/* Nano Templates (Tomasz Mazur, Jacek Becela) */

;(function ($) {
  $.nano = function (template, data) {
    // eslint-disable-next-line no-useless-escape
    return template.replace(/\{([\w\.]*)\}/g, function (str, key) {
      var keys = key.split('.'); var value = data[keys.shift()]
      $.each(keys, function () { value = value[this] })
      return (value === null || value === undefined) ? '' : value
    })
  }
})(window.jQuery)
